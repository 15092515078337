@import './sncf';
@import './axa';

@font-face {
  font-family: 'Publico Banner Medium';
  src: url('fonts/Publico Banner Medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body,
#app {
  background-color: rgb(252, 252, 252);
  height: 100%;
  margin: 0;
}

body {
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* react root */
#app > div {
  min-height: 100%;
}

a,
a:active,
a:visited {
  font-weight: bold;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

video {
  width: 100%;
}

.wrapper {
  position: relative;
}

.app {
  min-height: calc(100vh - 50px);
  padding-bottom: 50px;
}

.tab-buttons {
  margin: 0 auto;
  max-width: 700px;
}

.tab {
  margin: 0 auto;
  max-width: 800px;
}

.noHeight {
  max-height: 300px;
  overflow: hidden;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 75%,
    rgba(0, 0, 0, 0.9) 80%,
    rgba(0, 0, 0, 0) 100%
  );
}

.ui-card {
  background-color: rgb(255, 255, 255) !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
  margin: 0 10px 20px 10px; /* on mobile there is still left and right margin */
}

/* stylelint-disable-next-line no-descending-specificity */
.ui-card > div {
  padding-bottom: 0 !important;
}

.ui-card-reversed {
  margin: 20px 10px 0 10px;
}

/* flexbox with space-between: if only 1 item, makes it be centered */
.ui-grid::before,
.ui-grid::after {
  content: '';
}

.form-submit-fab-tabwrapper {
  position: sticky;
  bottom: 50px;
  z-index: 1400;
  height: 0;
}

.form-submit-fab {
  position: absolute;
  right: 23px;
  bottom: -4px;
}

/****************************
 ***** TOOLTIP SPECIALS *****
 ****************************/

.ph-tooltip {
  opacity: 1;
  transform-origin: center bottom 0;
  z-index: 2100;
}

.ph-tooltip .rc-tooltip-arrow {
  display: none;
}

.ph-tooltip .rc-tooltip-inner {
  background: #616161;
  background: rgba(97, 97, 97, 0.9);
  border-radius: 2px;
  min-height: initial;
  padding: 6px 8px;
}

/*****************************
 ***** FILEDROP SPECIALS *****
 *****************************/

.file-drop {
  position: relative;
  height: auto;
  width: 100%;
}

.file-drop > .file-drop-target {
  height: 0;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  transition: all 150ms linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  height: 100px;
  background-color: rgba(0, 0, 0, 0.65);
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  color: #ff6e40;
}

/***************************
 ***** MOBILE SPECIALS *****
 ***************************/

@media only screen and (max-width: 600px) {
  .hide-on-phone {
    display: none !important;
  }
}

/* @media only screen and (max-width: 767px) {
  .form-submit-fab-tabwrapper {
    bottom: 73px;
  }
} */

/******************************
 ***** OPACITY TRANSITION *****
 ******************************/

.opacityTransition-appear,
.opacityTransition-enter {
  opacity: 0.01;
}

.opacityTransition-appear.opacityTransition-appear-active,
.opacityTransition-enter.opacityTransition-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.opacityTransition-leave {
  opacity: 1;
}

.opacityTransition-leave.opacityTransition-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/****************************
 ***** SCALE TRANSITION *****
 ****************************/

.scaleTransition-appear,
.scaleTransition-enter {
  transform: scale(0.2);
}

.scaleTransition-appear.scaleTransition-appear-active,
.scaleTransition-enter.scaleTransition-enter-active {
  transform: scale(1);
  transition: transform 150ms ease-in;
}

.scaleTransition-leave {
  transform: scale(1);
}

.scaleTransition-leave.scaleTransition-leave-active {
  transform: scale(0.2);
  transition: transform 150ms ease-in;
}

/**************************
 ***** MATERIAL ICONS *****
 **************************/

/* http://google.github.io/material-design-icons/#icon-font-for-the-web */

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: #808080;
  color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
  color: #c0c0c0;
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: #fff;
}

.material-icons.md-light.md-inactive {
  color: #c0c0c0;
  color: rgba(255, 255, 255, 0.3);
}

/*************************
 ***** LOGIN BUTTONS *****
 *************************/

.login-buttons {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.login-buttons > * {
  flex-shrink: 0;
  margin: 0 5px;
}

.login-button {
  border: none;
  padding: 0;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  cursor: pointer;
}

.login-button.disabled {
  cursor: initial;
}

.sncf-login-button {
  background-image: url('./img/signin_buttons/sncf.png');

  /*  background-color: #fff; */
  border-radius: 2px;
  padding: 4px 58px 4px 10px;
  background-position: right;
  background-size: contain;
  line-height: 30px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
}

.sncf-login-button.disabled {
  background-color: #e5e5e5;
  box-shadow: initial;
}

.sncf-login-button > span {
  color: #4698cb;
}

.sncf-login-button.disabled > span {
  color: grey;
}

.yammer-login-button {
  width: 251px;
  height: 40px;
  background-image: url('https://d2bz2v6wegwutd.cloudfront.net/static/assets/yammer_signin_buttons/yammer.png');
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
}

.yammer-login-button.disabled {
  color: grey;
  background-color: #e5e5e5;
  box-shadow: initial;
}

.google-login-button {
  width: 191px;
  height: 46px;
  background-image: url('https://d2bz2v6wegwutd.cloudfront.net/static/assets/google_signin_buttons/web/1x/btn_google_signin_light_normal_web.png');
}

.google-login-button.disabled {
  background-image: url('https://d2bz2v6wegwutd.cloudfront.net/static/assets/google_signin_buttons/web/1x/btn_google_signin_light_disabled_web.png');
}

.google-login-button:focus {
  background-image: url('https://d2bz2v6wegwutd.cloudfront.net/static/assets/google_signin_buttons/web/1x/btn_google_signin_light_focus_web.png');
}

.google-login-button:active {
  background-image: url('https://d2bz2v6wegwutd.cloudfront.net/static/assets/google_signin_buttons/web/1x/btn_google_signin_light_pressed_web.png');
}

.msft-login-button {
  background-image: url('./img/signin_buttons/ms-symbollockup_mssymbol_19.svg');

  /*  background-color: #fff; */
  border-radius: 2px;
  padding: 4px 16px 4px 50px;
  background-position: left;
  background-size: contain;
  line-height: 30px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
}

.msft-login-button.disabled {
  background-color: #e5e5e5;
  box-shadow: initial;
}

.msft-login-button > span {
  // color: #4698cb;
}
