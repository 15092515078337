.slick-image {
  max-width: 100%;
  margin: 0 auto;
}

.slick-slide {
  transform: scale(0.85);
  opacity: 0.6;
  transition: all 0.3s ease;
}

.slick-current {
  transform: scale(1);
  opacity: 1;
}

.slick-track {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

@media (max-width: 480px) {
  .slick-image {
    max-width: 100%;
  }
}
