#app {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Georgia';
    color:rgb(2,113,128);
  }
}

.axa--header {
  height: 120px;

  @media only screen and (max-width: 900px) {
    height: 200px;
  }
}

.axa--header--wrapper {
  @media only screen and (max-width: 900px) {
    padding: 0 7px;
  }

  @media only screen and (max-width: 390px) {
    padding: 0;
  }

  // .axa--header--spacer-1 {
  //   @media only screen and (max-width: 600px) {
  //     flex-grow: 0;
  //     flex-basis: 20px;
  //     order: 1;
  //   }
  // }

  // .axa--header--spacer-2 {
  //   @media only screen and (max-width: 600px) {
  //     flex-basis: 100%;
  //   }
  // }

  // .axa--header--logo1,
  // .axa--header--logo2 {
  //   @media only screen and (max-width: 600px) {
  //     order: 1;
  //   }
  // }
}

.axa--signature {
  color: rgb(228, 0, 59);
  font-family: 'Publico Banner Medium';
  text-align: right;
}
